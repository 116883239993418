<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="center_top">
            <div class="top_1">总邀请：</div>
            <div class="top_3">{{ count }}人</div>
            <div class="date-filter">
              <span>加入时间:</span>
              <el-date-picker
                :clearable="false"
                :editable="false"
                v-model="begin_date"
                type="date"
                placeholder="开始时间"
                @change="chooseStart"
              >
              </el-date-picker>
              <div class="line"></div>
              <el-date-picker
                :clearable="false"
                :editable="false"
                v-model="end_date"
                type="date"
                placeholder="结束时间"
                @change="chooseEnd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="list_head">
            <div class="list_ever">头像</div>
            <div class="list_ever">名称</div>
            <div class="list_ever">时间</div>
          </div>
          <div class="list">
            <div class="loop" v-for="(item, index) in list" :key="index">
              <div class="loop_over">
                <img :src="net + item.headimg" alt="" />
              </div>
              <div class="loop_over">
                {{ item.name }}
              </div>
              <div class="loop_over">
                {{ (item.create_at * 1000) | format("YYYY-MM-DD HH:mm") }}
              </div>
            </div>
          </div>

          <el-pagination
            class="page"
            :page-size="limit"
            background
            layout="prev, pager, next"
            :total="count"
            :current-page="page"
            @current-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getInvite } from "@network/mine";
export default {
  name: "Detailed",
  data() {
    return {
      net: BASE_URL,
      list: [],
      count: 0,
      page: 1,
      limit: 10,
      begin_date: "",
      end_date: "",
      startDate: "",
      endDate: "",
    };
  },
  created() {
    this.getInvite();
  },
  components: {},
  methods: {
    handleSizeChange(val) {
      this.page = val;
      this.getInvite();
    },

    format(num) {
      if (num < 10) return "0" + num;
      return num;
    },
    //选择开始时间
    chooseStart() {
      this.startDate =
        new Date(this.begin_date).getFullYear() +
        "" +
        this.format(new Date(this.begin_date).getMonth() + 1) +
        "" +
        this.format(new Date(this.begin_date).getDate());
      this.list = [];
      this.page = 1;
      this.getInvite();
    },
    //选择结束时间
    chooseEnd() {
      this.endDate =
        new Date(this.end_date).getFullYear() +
        "" +
        this.format(new Date(this.end_date).getMonth() + 1) +
        "" +
        this.format(new Date(this.end_date).getDate());
      this.list = [];
      this.page = 1;
      this.getInvite();
    },

    //获取下级
    getInvite() {
      getInvite(this.page, this.limit, this.startDate, this.endDate).then(
        (res) => {
          console.log(res);
          if (res.code != 0) {
            if (res.code == 403) {
              this.$storage.remove("token");
              this.$message.error({ message: "请重新登录", offset: 300 });
              this.$router.replace("/home");
              return false;
            }
            this.$message.error({
              message: "获取记录失败",
              offset: 300,
            });
            return false;
          }
          this.count = res.count;
          this.list.push(...res.data);
        }
      );
    },
  },
};
</script>
<style lang='less' scoped>
@import "@assets/css/vipCenter/ChildComps/friendList.less";
</style>
